import { gql } from '@apollo/client';
import MoneyFields from '../fragments/money/money-fields';

export const PreofferEvaluationInfo = gql`
  fragment PreofferEvaluationInfo on PreOfferEvaluationRequestType {
    id
    status
    resolvedDate
    requestDate
    evaluationAnalystDescription
    finalAnalystDescription
    automaticDecision
    observation
    rejectionReasonLabel
    otherRejectionReason
    ratification
    createdAt
    requestingPlatform {
      id
      code
    }
  }
`;

export const AutomaticRiskEvaluationResultFields = gql`
  fragment AutomaticRiskEvaluationResultFields on RiskAutomaticEvaluationLogType {
    id
    offeredValue
    offeredModelThreshold
    rejectedModelThreshold
    riskPredictionResult
    ratificationResult
    clientCreditLineAmountLeft {
      ...MoneyFields
    }
    debtorCreditLineAmountLeft {
      ...MoneyFields
    }
    clientLineResult
    debtorLineResult
    resolution
    resolutionComment
  }
  ${MoneyFields}
`;

export const EstimatedResolutionDatetimeRuleFields = gql`
  fragment EstimatedResolutionDatetimeRuleFields on EstimatedResolutionDatetimeRuleType {
    id
    requestingPlatform {
      id
      masterEntity {
        id
        name
      }
    }
    minAmount {
      ...MoneyFields
    }
    maxAmount {
      ...MoneyFields
    }
    clientIsNew
    receiverIsNew
    clientHasCreditLine
    sla
  }
`;

export const SLAResolutionFields = gql`
  fragment SLAResolutionFields on SLAResolutionType {
    id
    estimatedResolutionDatetime
    estimatedResolution {
      ...EstimatedResolutionDatetimeRuleFields
    }
  }
  ${EstimatedResolutionDatetimeRuleFields}
`;
