import { gql } from '@apollo/client';
import {
  CollectionActionsFields,
  CollectionManagerBasicFields,
  CollectionPriorityFields,
} from '../collection/fragments';
import {
  AutomaticRiskEvaluationResultFields,
  PreofferEvaluationInfo,
  SLAResolutionFields,
} from '../evaluation_requests/fragments';
import { BasicUserFields } from '../fragments';
import {
  FundBasicField,
  InvoiceLoanManagerBasicField,
  InvoiceLoanRosterBasic,
} from '../invoice-loan/fragments';
import {
  AssignmentFields,
  DocumentFinanceStateFields,
  InvoiceAlertsFields,
  InvoiceDocumentsFields,
  InvoiceDteTypeFields,
  InvoiceIdFields,
  InvoiceReferenceFields,
  InvoiceStatusFields,
  TraceFields,
} from '../invoices/fragments';
import MoneyFields from '../fragments/money/money-fields';
import { RatificationActionsBasicFields } from '../ratification/fragments';
import { UserIdentification } from '../user/fragments';
import {
  CompanyDebtsSummary,
  CompanyDocumentsFields,
  CompanyReceiverEvaluationCommentFields,
  CompanyRestrictions,
  CredentialFields,
  CreditLineEvaluationRequestFields,
  CreditLineFields,
  CreditLineMovementFields,
  CreditLineSuggestionTypeFields,
  CreditLineTransferTypeFields,
  CreditlineEvaluationIndicatorsTypeFields,
  CreditlineEvaluationVariablesTypeFields,
  EvaluationCommentFields,
  HasCredentialFields,
  MasterEntityBasicField,
  MasterEntityPublicInfoType,
  RateWhitelistFields,
  ReceiverDebtsSummary,
  RiskBlacklistFields,
} from './fragment';

export const GET_REQUESTING_PLATFORMS = gql`
  query getRequestingPlatforms {
    getRequestingPlatforms {
      id
      code
    }
  }
`;

export const GET_REQUESTING_PLATFORM = gql`
  query getRequestingPlatform($id: String!) {
    getRequestingPlatform(id: $id) {
      id
      code
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query GetCompanyBankAccounts($companyIds: [Int]!) {
    getCompanyBankAccounts(companyIds: $companyIds) {
      id
      accountEmail
      accountName
      accountNumber
      accountRut
      accountType
      bankName
      active
    }
  }
`;

export const GET_BANK_ACCOUNT_TYPES = gql`
  query getBankAccountTypesByCountry($countryId: String!) {
    getBankAccountTypesByCountry(countryId: $countryId) {
      id
      name
    }
  }
`;

export const GET_BANKS = gql`
  query getBankOptionsByCountry($countryId: String!) {
    getBankOptionsByCountry(countryId: $countryId) {
      id
      name
    }
  }
`;

export const PORTFOLIO_OPTIONS = gql`
  query portfolioOptions {
    getPortfolioTypeEnum {
      value
      label
    }
    getPortfolioFormatEnum {
      value
      label
    }
  }
`;

export const GET_COMPANY_PREFERENCES = gql`
  query {
    getCompanyPreferences {
      suppliers_Merit {
        id
      }
      suppliers_Acknowledgment {
        id
      }
    }
  }
`;

export const GET_INVOICE_PROVIDER = gql`
  query geInvoiceProvider($credentialProviderType_Name: String) {
    getInvoiceProvider(
      credentialProviderType_Name: $credentialProviderType_Name
    ) {
      id
      name
      integrated
      iconUrl
      requiresEmail
      credentialProviderType {
        id
        name
        description
      }
    }
  }
`;

export const GET_NATIONAL_INVOICE_PROVIDER = gql`
  query getNationalInvoiceProvider($countryId: String!) {
    getNationalInvoiceProvider(countryId: $countryId) {
      id
      name
    }
  }
`;

export const SOPHIA_TRACKER = gql`
  query SophiaTracker($companyId: Int!) {
    sophiaTracker(companyId: $companyId) {
      companyRut
      sales
      salesPast
      salesCount
      salesCountPast
      shop
      shopPast
      shopCount
      shopCountPast
      cessions
      cessionsPast
      cessionsCount
      cessionsCountPast
      cessionsShop
      cessionsShopPast
      cessionsShopCount
      cessionsShopCountPast
      shopExpires
      salesExpires
    }
  }
`;

export const SOPHIA_GRAPHS = gql`
  query SophiaGraphs(
    $accumulated: Boolean!
    $group: String!
    $companyId: Int!
  ) {
    sophiaPurchasesGraph(
      accumulated: $accumulated
      group: $group
      companyId: $companyId
    ) {
      money
      year
      month
      dateIssued
    }
    sophiaSalesGraph(
      accumulated: $accumulated
      group: $group
      companyId: $companyId
    ) {
      money
      year
      month
      dateIssued
    }
  }
`;

export const GET_SIMPLE_MASTER_ENTITIES = gql`
  query getMasterEntities(
    $first: Int
    $offset: Int
    $orderBy: String
    $countryId: Int
    $globalFilter: String
    $companyClients: Int
    $financinginstitution_Isnull: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      orderBy: $orderBy
      countryId: $countryId
      globalFilter: $globalFilter
      companyClients: $companyClients
      financinginstitution_Isnull: $financinginstitution_Isnull
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...MasterEntityBasicField
        }
      }
    }
  }
  ${MasterEntityBasicField}
`;

export const CONTACTS = gql`
  query contacts(
    $masterEntity_In: [ID]
    $orderBy: String
    $first: Int
    $offset: Int
    $contactType: String
    $showHidden: Boolean
  ) {
    getContacts(
      masterEntity_In: $masterEntity_In
      orderBy: $orderBy
      first: $first
      offset: $offset
      contactType_Code: $contactType
      showHidden: $showHidden
    ) {
      edges {
        node {
          id
          name
          position
          email
          phoneNumber
          source
          contactType {
            code
          }
          masterEntity {
            id
          }
          userInstance {
            id
            activeInvitations {
              companyFrom {
                id
                rut
              }
            }
          }
        }
      }
    }
  }
`;

export const PROVIDER_EXCEL_TEMPLATE = gql`
  query exportTemplateContacts {
    exportTemplateContacts
  }
`;

export const TERMS_AND_CONDITIONS = gql`
  query TermsAndConditions($countryId: Int!) {
    termsAndConditions(last: 1, countryId: $countryId) {
      edges {
        node {
          id
          sections {
            id
            text
            sectionType
          }
        }
      }
    }
  }
`;

export const INVOICE_DEBTOR_SUMMARY = gql`
  query invoiceDebtorSummary(
    $ownerId: ID!
    $debtorId: String!
    $currency: String
  ) {
    debtorSummary(ownerId: $ownerId, debtorId: $debtorId, currency: $currency) {
      currentDebtAmount {
        ...MoneyFields
      }
      badDebtAmount {
        ...MoneyFields
      }
      averagePaymentTerm
      collectedInvoices
      lateCollectedInvoices
    }
  }
  ${MoneyFields}
`;

export const ORDERING_DEBTOR_SUMMARY = gql`
  query orderingDebtorSummary($debtorId: String!) {
    operationsSummary(debtorId: $debtorId) {
      debtCount
      debtAmount
      currentValid
      currentAmount
    }
  }
`;

export const GET_MASTER_ENTITY = gql`
  query masterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      rut
      name
      debtor {
        id
        classification {
          id
          code
        }
      }
      contacts(first: 1, showHidden: false) {
        edges {
          node {
            id
            name
            position
            email
            phoneNumber
          }
        }
      }
      company {
        id
        rut
        documents {
          ...CompanyDocumentsFields
        }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

const CollectionMasterEntityFragment = gql`
  fragment CollectionMasterEntityFragment on MasterEntityType {
    ...MasterEntityBasicField
    riskBlacklist {
      ...RiskBlacklistFields
    }
    debtor {
      id
      collectionPriority(ownerId: $inCollection) {
        id
        debtor {
          id
        }
        priority {
          id
          value
        }
      }
      collector {
        ...UserIdentification
      }
    }
  }
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${RiskBlacklistFields}
`;

export const KOBROS_GET_MASTER_ENTITY = gql`
  query kobrosGetMasterEntity($masterEntityId: Int!, $inCollection: Int) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      ...CollectionMasterEntityFragment
      debtor {
        id
        classification {
          id
          code
        }
      }
      contacts(first: 1, showHidden: false) {
        edges {
          node {
            id
            name
            position
            email
            phoneNumber
          }
        }
      }
      company {
        id
        rut
        documents {
          ...CompanyDocumentsFields
        }
      }
    }
  }
  ${CompanyDocumentsFields}
  ${CollectionMasterEntityFragment}
`;

const PendingCollectionManagerFragment = gql`
  fragment PendingCollectionManagerFragment on CollectionManagerType {
    ...CollectionManagerBasicFields
    collectedObjectAmount {
      ...MoneyFields
    }
    collectionPriority {
      ...CollectionPriorityFields
    }
    collectedObjectDateToPay
    collectedObjectDocumentIdentifier
    actions {
      ...CollectionActionsFields
      author {
        ...UserIdentification
      }
    }
  }
  ${CollectionManagerBasicFields}
  ${MoneyFields}
  ${CollectionPriorityFields}
  ${CollectionActionsFields}
  ${UserIdentification}
`;

export const FACTORING_COLLECTION_MASTER_ENTITIES = gql`
  query factoringCollectionMasterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $debtor_CollectionPriorities_Priority_Value: Int
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inFactoringCollection: $inCollection
      orderBy: $orderBy
      debtor_CollectionPriorities_Priority_Value: $debtor_CollectionPriorities_Priority_Value
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...CollectionMasterEntityFragment
          pendingCollectionManagers: pendingFactoringCollectionManagers {
            ...PendingCollectionManagerFragment
          }
        }
      }
    }
  }
  ${CollectionMasterEntityFragment}
  ${PendingCollectionManagerFragment}
`;
export const ORDERING_COLLECTION_MASTER_ENTITIES = gql`
  query orderingCollectionMasterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $debtor_CollectionPriorities_Priority_Value: Int
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inOrderingCollection: $inCollection
      orderBy: $orderBy
      debtor_CollectionPriorities_Priority_Value: $debtor_CollectionPriorities_Priority_Value
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...CollectionMasterEntityFragment
          pendingCollectionManagers: pendingOrderingCollectionManagers {
            ...PendingCollectionManagerFragment
          }
        }
      }
    }
  }
  ${CollectionMasterEntityFragment}
  ${PendingCollectionManagerFragment}
`;
export const KOBROS_COLLECTION_MASTER_ENTITIES = gql`
  query kobrosCollectionMasterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $debtor_CollectionPriorities_Priority_Value: Int
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inCollection: $inCollection
      orderBy: $orderBy
      debtor_CollectionPriorities_Priority_Value: $debtor_CollectionPriorities_Priority_Value
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...CollectionMasterEntityFragment
          pendingCollectionManagers: pendingInCollectionInvoice {
            ...PendingCollectionManagerFragment
          }
          debtor {
            debtorPreferences(ownerId: $inCollection) {
              id
              expirationDays
            }
          }
        }
      }
    }
  }
  ${CollectionMasterEntityFragment}
  ${PendingCollectionManagerFragment}
`;

export const NON_PRIORITY_RATIFICATION_MASTER_ENTITY = gql`
  query nonPriorityRatificationMasterEntity(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inRatification: Boolean
    $countryId: Int
    $debtor_RatificationPriority_Value: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inNonPriorityRatification: $inRatification
      orderBy: $orderBy
      countryId: $countryId
      debtor_RatificationPriority_Value: $debtor_RatificationPriority_Value
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          ...ReceiverDebtsSummary
          riskBlacklist {
            ...RiskBlacklistFields
          }
          debtor {
            id
            ratificator {
              ...UserIdentification
            }
          }
          invoicesInRatification: invoicesInNonPriorityRatification {
            ...InvoiceIdFields
            ratificationmanager {
              id
              actions {
                ...RatificationActionsBasicFields
                author {
                  ...UserIdentification
                }
              }
            }
          }
        }
      }
    }
  }
  ${ReceiverDebtsSummary}
  ${MasterEntityBasicField}
  ${InvoiceIdFields}
  ${UserIdentification}
  ${RatificationActionsBasicFields}
  ${RiskBlacklistFields}
`;

export const PRIORITY_RATIFICATION_MASTER_ENTITY = gql`
  query priorityRatificationMasterEntity(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inRatification: Boolean
    $countryId: Int
    $debtor_RatificationPriority_Value: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inPriorityRatification: $inRatification
      orderBy: $orderBy
      countryId: $countryId
      debtor_RatificationPriority_Value: $debtor_RatificationPriority_Value
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          ...ReceiverDebtsSummary
          riskBlacklist {
            ...RiskBlacklistFields
          }
          debtor {
            id
            ratificator {
              ...UserIdentification
            }
            ratificationPriority {
              ...CollectionPriorityFields
            }
          }
          invoicesInRatification: invoicesInPriorityRatification {
            ...InvoiceIdFields
            ratificationmanager {
              id
              actions {
                ...RatificationActionsBasicFields
                author {
                  ...UserIdentification
                }
              }
            }
          }
        }
      }
    }
  }
  ${ReceiverDebtsSummary}
  ${MasterEntityBasicField}
  ${InvoiceIdFields}
  ${UserIdentification}
  ${RatificationActionsBasicFields}
  ${CollectionPriorityFields}
  ${RiskBlacklistFields}
`;

export const GET_CREDIT_LINES = gql`
  query getMasterEntityCreditLines(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
  ) {
    getMasterEntities(
      creditlines_Isnull: false
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      countryId: $countryId
      distinct: true
    ) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalPages
      totalCount
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          riskBlacklist {
            ...RiskBlacklistFields
          }
          creditlines {
            ...CreditLineFields
          }
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
  ${MoneyFields}
  ${RiskBlacklistFields}
`;

export const GET_DEBTORS = gql`
  query getDebtors(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $debtor_Isnull: Boolean
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inCollection: $inCollection
      orderBy: $orderBy
      debtor_Isnull: $debtor_Isnull
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          ...MasterEntityBasicField
          riskBlacklist {
            ...RiskBlacklistFields
          }
          debtor {
            id
            ratificator {
              ...BasicUserFields
            }
            collector {
              ...BasicUserFields
            }
          }
        }
      }
    }
  }
  ${MasterEntityBasicField}
  ${BasicUserFields}
  ${RiskBlacklistFields}
`;

export const GET_COMPANY_EVALUATIONS = gql`
  query invoices(
    $companyId: Int
    $hasEvaluatingPreoffer: Boolean
    $hasPendingDocuments: Boolean
    $first: Int
    $offset: Int
    $orderBy: String
    $currency: String
  ) {
    invoices(
      companyId: $companyId
      hasEvaluatingPreoffer: $hasEvaluatingPreoffer
      hasPendingDocuments: $hasPendingDocuments
      first: $first
      offset: $offset
      orderBy: $orderBy
      currency: $currency
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          ...InvoiceIdFields
          ...InvoiceStatusFields
          ...InvoiceReferenceFields
          ...InvoiceAlertsFields
          invoiceloanmanager {
            ...InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ...InvoiceLoanRosterBasic
              fund {
                ...FundBasicField
                masterEntity {
                  ...MasterEntityBasicField
                }
              }
            }
          }
          documentfinancestateSet {
            ...DocumentFinanceStateFields
          }
          preoffer {
            id
            monthlyRate
            defaultRate
            retentionRate
            preofferevaluationrequest {
              ...PreofferEvaluationInfo
              riskautomaticevaluationlogSet {
                ...AutomaticRiskEvaluationResultFields
              }
              slaResolution {
                ...SLAResolutionFields
              }
            }
          }
          traces {
            ...TraceFields
          }
          uniqueDocumentFinanceState {
            ...DocumentFinanceStateFields
          }
          dteType {
            ...InvoiceDteTypeFields
          }
          assignmentSet {
            ...AssignmentFields
          }
          amountWithIva {
            ...MoneyFields
          }
          documents {
            ...InvoiceDocumentsFields
          }
          company {
            id
            name
            rut
            masterEntity {
              ...MasterEntityBasicField
              evaluationComments {
                ...EvaluationCommentFields
              }
              riskBlacklist {
                ...RiskBlacklistFields
                comments
                createdAt
              }
              ratificationBlacklist {
                id
                active
              }
              ...CompanyDebtsSummary
            }
            currentIvaObservation {
              id
              observation
              createdAt
            }
            currentDicomObservation {
              id
              observation
              createdAt
            }
            currentCreditLine {
              id
              totalAmount {
                ...MoneyFields
              }
              approvedOverdraft {
                ...MoneyFields
              }
              creditUsed {
                ...MoneyFields
              }
            }
            executiveAssigned {
              ...UserIdentification
            }
            cessionsHistory {
              id
              cessionsNumber
              cessionsMoneyAmount
              historicCessionsNumber
              historicCessionsMoneyAmount
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
            conservativeTycRequestingPlatform {
              id
              code
            }
          }
          receiver {
            ...MasterEntityBasicField
            evaluationComments {
              ...EvaluationCommentFields
            }
            ...ReceiverDebtsSummary
            riskBlacklist {
              ...RiskBlacklistFields
              comments
              createdAt
            }
            ratificationBlacklist {
              id
              active
            }
            debtor {
              id
              ratificationInformation {
                id
                comment
              }
            }
            receiverCessionsHistory {
              id
              cessionsNumber
              cessionsMoneyAmount
              historicCessionsNumber
              historicCessionsMoneyAmount
            }
          }
          receiverDocumentRequested {
            id
            hesPercentage
            purchaseOrderPercentage
            invoicesAmount
          }
          riskEvaluationModel {
            id
            treeClientResult
            treeDebtorResult
            treeClientDebtorResult
            rasClientResult
            rasDebtorResult
            rasClientDebtorResult
            ratification
            reason
            ratificationResults {
              id
              createdAt
              modelProbability
              thresholdUsed
              ratificationResult
            }
            riskPredictionResults {
              id
              isAccepted
              isRejected
            }
            riskDefaultResults {
              id
              isAccepted
              isRejected
            }
          }
          companyReceiverCessionHistory {
            id
            cessionsNumber
            cessionsMoneyAmount
            historicCessionsNumber
            historicCessionsMoneyAmount
          }
          companyReceiverEvaluationComment {
            ...CompanyReceiverEvaluationCommentFields
          }
        }
      }
    }
  }
  ${SLAResolutionFields}
  ${ReceiverDebtsSummary}
  ${CompanyDebtsSummary}
  ${MoneyFields}
  ${InvoiceDocumentsFields}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${MasterEntityBasicField}
  ${FundBasicField}
  ${AssignmentFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceReferenceFields}
  ${DocumentFinanceStateFields}
  ${InvoiceDteTypeFields}
  ${UserIdentification}
  ${PreofferEvaluationInfo}
  ${AutomaticRiskEvaluationResultFields}
  ${InvoiceAlertsFields}
  ${EvaluationCommentFields}
  ${CompanyReceiverEvaluationCommentFields}
  ${TraceFields}
  ${CompanyRestrictions}
  ${RiskBlacklistFields}
`;

export const GET_COMPANY_PENDING_DOCUMENTS_EVALUATIONS = gql`
  query invoices(
    $companyId: Int
    $hasEvaluatingPreoffer: Boolean
    $hasPendingDocuments: Boolean
    $first: Int
    $offset: Int
    $orderBy: String
  ) {
    invoices(
      companyId: $companyId
      hasEvaluatingPreoffer: $hasEvaluatingPreoffer
      hasPendingDocuments: $hasPendingDocuments
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          ...InvoiceIdFields
          ...InvoiceStatusFields
          ...InvoiceReferenceFields
          ...InvoiceAlertsFields
          restrictedBy {
            ...CompanyRestrictions
          }
          documentfinancestateSet {
            ...DocumentFinanceStateFields
          }
          preoffer {
            id
            monthlyRate
            defaultRate
            retentionRate
            preofferevaluationrequest {
              ...PreofferEvaluationInfo
            }
          }
          traces {
            ...TraceFields
          }
          uniqueDocumentFinanceState {
            ...DocumentFinanceStateFields
          }
          dteType {
            ...InvoiceDteTypeFields
          }
          assignmentSet {
            ...AssignmentFields
          }
          amountWithIva {
            ...MoneyFields
          }
          documents {
            ...InvoiceDocumentsFields
          }
          company {
            id
            name
            rut
            masterEntity {
              ...MasterEntityBasicField
            }
            executiveAssigned {
              ...UserIdentification
            }
            cessionsHistory {
              id
              cessionsNumber
              cessionsMoneyAmount
              historicCessionsNumber
              historicCessionsMoneyAmount
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
          }
          receiver {
            ...MasterEntityBasicField
            ...ReceiverDebtsSummary
            receiverCessionsHistory {
              id
              cessionsNumber
              cessionsMoneyAmount
              historicCessionsNumber
              historicCessionsMoneyAmount
            }
          }
          receiverDocumentRequested {
            id
            hesPercentage
            purchaseOrderPercentage
            invoicesAmount
          }
          companyReceiverCessionHistory {
            id
            cessionsNumber
            cessionsMoneyAmount
            historicCessionsNumber
            historicCessionsMoneyAmount
          }
        }
      }
    }
  }
  ${ReceiverDebtsSummary}
  ${MoneyFields}
  ${InvoiceDocumentsFields}
  ${MasterEntityBasicField}
  ${AssignmentFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceReferenceFields}
  ${DocumentFinanceStateFields}
  ${InvoiceDteTypeFields}
  ${UserIdentification}
  ${PreofferEvaluationInfo}
  ${InvoiceAlertsFields}
  ${TraceFields}
  ${CompanyRestrictions}
`;

export const GET_COMPANY_ORDERING_EVALUATIONS = gql`
  query getCompanyOrderingEvaluations(
    $companyId: Int!
    $inEvaluation: Boolean
    $first: Int
    $offset: Int
    $orderBy: String
    $inDebt: Boolean
  ) {
    purchaseOrders(
      companyId: $companyId
      inEvaluation: $inEvaluation
      inDebt: $inDebt
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          orderNumber
          company {
            id
            rut
            name
            masterEntity {
              id
              displayNationalIdentifier
              riskBlacklist {
                ...RiskBlacklistFields
                comments
                createdAt
              }
              evaluationComments {
                ...EvaluationCommentFields
              }
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
          }
          purchaser {
            id
            rut
            name
            displayNationalIdentifier
            riskBlacklist {
              ...RiskBlacklistFields
              comments
              createdAt
            }
            ratificationBlacklist {
              id
              active
            }
            evaluationComments {
              ...EvaluationCommentFields
            }
            ...ReceiverDebtsSummary
          }
          orderingsimulation {
            id
            invoiceDateToPay
            invoiceIssuedDate
            orderingMonthlyRate
            orderingDefaultRate
            orderingRetentionRate
            factoringMonthlyRate
            factoringDefaultRate
            factoringRetentionRate
            slaResolution {
              ...SLAResolutionFields
            }
          }
          totalAmount {
            ...MoneyFields
          }
          riskEvaluationModel {
            id
            treeClientResult
            treeDebtorResult
            treeClientDebtorResult
            rasClientResult
            rasDebtorResult
            rasClientDebtorResult
            ratification
            reason
          }
          companyReceiverEvaluationComment {
            ...CompanyReceiverEvaluationCommentFields
          }
        }
      }
    }
  }
  ${SLAResolutionFields}
  ${ReceiverDebtsSummary}
  ${MoneyFields}
  ${EvaluationCommentFields}
  ${CompanyReceiverEvaluationCommentFields}
  ${CompanyRestrictions}
  ${RiskBlacklistFields}
`;

export const GET_CUSTOMERS_BASICS = gql`
  query getCustomers(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $inEvaluation: Boolean = false
    $company_Isnull: Boolean = false
    $withInvoiceRecommendation: [String] = []
    $loggedExecutive: Boolean = false
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inCollection: $inCollection
      inEvaluation: $inEvaluation
      orderBy: $orderBy
      company_Isnull: $company_Isnull
      withInvoiceRecommendation: $withInvoiceRecommendation
      loggedExecutive: $loggedExecutive
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          rut
          name
          displayNationalIdentifier
          company {
            rut
          }
        }
      }
    }
  }
`;

export const COMMERCIAL_MASTER_ENTITIES = gql`
  query commercialMasterEntities(
    $id_In: [String]
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $loggedExecutive: Boolean
    $countryId: Int
    $companyExecutiveAssignedId: ID
    $farmingByCommercialType: String
    $sourceName: String
  ) {
    getMasterEntities(
      id_In: $id_In
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      loggedExecutive: $loggedExecutive
      countryId: $countryId
      company_ExecutiveAssignedId: $companyExecutiveAssignedId
      huntingFarmingFilter: $farmingByCommercialType
      source_Name: $sourceName
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          riskBlacklist {
            ...RiskBlacklistFields
          }
          country {
            id
            name
          }
          creditLine {
            ...CreditLineFields
          }
          ...CompanyDebtsSummary
          company {
            id
            ...HasCredentialFields
            executiveAssigned {
              ...UserIdentification
            }
            documents {
              ...CompanyDocumentsFields
            }
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${MoneyFields}
  ${MasterEntityBasicField}
  ${CompanyDocumentsFields}
  ${UserIdentification}
  ${CreditLineFields}
  ${HasCredentialFields}
  ${RiskBlacklistFields}
`;

export const CONTRACT_MASTER_ENTITIES = gql`
  query contractMasterEntities(
    $id_In: [String]
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $loggedExecutive: Boolean
    $countryId: Int
    $companyExecutiveAssignedId: ID
    $farmingByCommercialType: String
    $inPendingFrameworkContract: Boolean
  ) {
    getMasterEntities(
      id_In: $id_In
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      loggedExecutive: $loggedExecutive
      countryId: $countryId
      company_ExecutiveAssignedId: $companyExecutiveAssignedId
      huntingFarmingFilter: $farmingByCommercialType
      inPendingFrameworkContract: $inPendingFrameworkContract
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          riskBlacklist {
            id
          }
          country {
            id
            name
          }
          company {
            id
            executiveAssigned {
              ...UserIdentification
            }
            documents {
              ...CompanyDocumentsFields
            }
          }
        }
      }
    }
  }
  ${MasterEntityBasicField}
  ${CompanyDocumentsFields}
  ${UserIdentification}
`;

export const PENDING_CONTRACTS = gql`
  query getCompanyFrameworkContractRequests(
    $inPendingFrameworkContract: Boolean
    $companyId: ID
  ) {
    getCompanyFrameworkContractRequests(
      inPendingFrameworkContract: $inPendingFrameworkContract
      companyId: $companyId
    ) {
      edges {
        node {
          id
          status
          companyContractType
          company {
            id
            masterEntity {
              ...MasterEntityBasicField
            }
          }
        }
      }
    }
  }
  ${MasterEntityBasicField}
`;

export const MASTER_ENTITIES_WHITELIST = gql`
  query whitelistMasterEntities(
    $id_In: [String]
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $loggedExecutive: Boolean
    $countryId: Int
    $filterByRateWhitelist: String
  ) {
    getMasterEntities(
      id_In: $id_In
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      loggedExecutive: $loggedExecutive
      countryId: $countryId
      filterByRateWhitelist: $filterByRateWhitelist
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          riskBlacklist {
            ...RiskBlacklistFields
          }
          ...CompanyDebtsSummary
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${MasterEntityBasicField}
  ${RiskBlacklistFields}
`;

export const DEBTOR_PAYMENT_BEHAVIOUR_ENUM = gql`
  query getDebtorPaymentBehaviourEnum {
    getDebtorPaymentBehaviourEnum {
      value
      label
    }
  }
`;

export const GET_MASTER_ENTITY_CONTACTS = gql`
  query GetMasterEntityContacts($masterEntityId: Int!) {
    getMasterEntityContacts(masterEntityId: $masterEntityId) {
      id
      name
      email
      phoneNumber
      position
    }
  }
`;

export const RELAY_NODE_INTERFACE = gql`
  query node($id: ID!) {
    node(id: $id) {
      ... on MasterEntityPublicInfoType {
        id
        name
        rut
        profilePictureUrl
      }
    }
  }
`;

export const FETCH_COMPANY_CTE = gql`
  mutation FetchCompanyCte($companyId: String!) {
    fetchCompanyCte(companyId: $companyId) {
      requestReceived
    }
  }
`;

export const FETCH_COMPANY_LEGAL_REPORT = gql`
  mutation fetchCompanyLegalReport($companyId: String!) {
    fetchCompanyLegalReport(companyId: $companyId) {
      requestReceived
    }
  }
`;

export const GET_COMPANY_ERUT = gql`
  mutation getCompanyERut($companyId: String!) {
    getCompanyErut(companyId: $companyId) {
      message
    }
  }
`;

export const FETCH_COMPANY_TGR = gql`
  mutation FetchCompanyTgr($companyId: String!) {
    fetchCompanyTgr(companyId: $companyId) {
      company {
        id
        documents {
          ...CompanyDocumentsFields
        }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

export const GET_COMPANY_BLACKLIST = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $inBlacklist: Boolean = true
    $financinginstitution_Isnull: Boolean = true
    $orderBy: String
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inBlacklist: $inBlacklist
      financinginstitution_Isnull: $financinginstitution_Isnull
      orderBy: $orderBy
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          displayNationalIdentifier
          riskBlacklist {
            ...RiskBlacklistFields
            comments
            createdAt
          }
          ...CompanyDebtsSummary
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${RiskBlacklistFields}
`;

export const ADD_COMPANIES_TO_BLACKLIST = gql`
  mutation addCompaniesToBlacklist(
    $ruts: [String]!
    $comments: String
    $blacklistType: String!
  ) {
    addCompaniesToBlacklist(
      ruts: $ruts
      comments: $comments
      blacklistType: $blacklistType
    ) {
      masterEntities {
        id
        riskBlacklist {
          ...RiskBlacklistFields
          comments
          createdAt
        }
      }
    }
  }
  ${RiskBlacklistFields}
`;

export const ADD_COMPANIES_TO_BLACKLIST_EXCEL = gql`
  mutation addCompaniesToBlacklistExcel(
    $file: Upload!
    $blacklistType: String!
  ) {
    addCompaniesToBlacklistExcel(file: $file, blacklistType: $blacklistType) {
      masterEntities {
        id
      }
    }
  }
`;

export const REMOVE_COMPANY_FROM_BLACKLIST = gql`
  mutation removeCompanyFromBlacklist($ids: [String]!) {
    removeCompanyFromBlacklist(ids: $ids) {
      success
    }
  }
`;

export const ADD_EVALUATION_COMMENT = gql`
  mutation addEvaluationComment(
    $masterEntity1: Int!
    $masterEntity2: Int
    $comment: String!
  ) {
    addEvaluationComment(
      masterEntity1: $masterEntity1
      masterEntity2: $masterEntity2
      comment: $comment
    ) {
      success
    }
  }
`;

export const CURRENT_BEHAVIOR_DETAILS = gql`
  query getCurrentBehavior($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      name
      currentBehaviors {
        id
        createdAt
        totalDebt
        dateInform
        currentBehaviorDetails {
          id
          createdAt
          date
          directCastDebt
          indirectCastDebt
          comercialDebt
          consumerCreditDebt
          contingentCreditDebt
          mortgageCreditDebt
          directExpired3Months3YearsDebt
          directValidDebt
          indirectExpiredDebt
          indirectValidDebt
          financialDebt
          leasingDebt
          moroseDebt
          moroseLeasingDebt
          operationCPactDebt
          directExpiredDebt
          currentCreditLine
          comercialExpiredAmountDebt
          comercialValidAmountDebt
          consumeCreditNumberCreditors
          numberConsumeCreditEntities
        }
      }
    }
  }
`;

export const BUSINESS_PROFILE_DETAILS = gql`
  query getBusinessProfile($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      name
      businessProfile {
        id
        createdAt
        tipoper
        economicActivity
        year
        cbrs
        clasification
        address
        activity
        morConsolidated
        labInformation
        protDocImp
        taxSituation
        numberWorkers
        businessName
        subActivity
        companySubtype
        companyType
        salesSection
        taxAppraisalAmount
        vehiclesAppraisal
        societies {
          id
          createdAt
          rut
          dv
          name
          capital
          beginDate
        }
        shareholders {
          id
          createdAt
          rut
          dv
          name
          participation
          dateVinculation
          dateInformation
          email
          type
          relatedMasterEntity {
            id
            isNaturalPersonRut
            riskBlacklist {
              ...RiskBlacklistFields
            }
            currentDicomPlatinum {
              id
              createdAt
              dicomAmount
            }
            currentDicom {
              id
              createdAt
              dicomAmount
            }
          }
        }
        details {
          id
          type
          createdAt
          year
          appraisal
          quality
          brand
          model
          chasisNumber
          motorNumber
          licensePlace
          ownerName
          ownerLastName
          ownerOrganization
          ownerRut
          ownerDv
          ranking
          carType
          use
        }
        judicialDetails {
          id
          createdAt
          type
          creditor
          clasification
          debtDays
          creditorDig
          date
          aclDate
          source
          glscodacteco
          identification
          process
          reference
          creditorRut
          url
        }
        taxAppraisal {
          id
          createdAt
          direction
          ubication
          taxAppraisal
          rol
          ranking
          quality
        }
      }
    }
  }
  ${RiskBlacklistFields}
`;

export const DICOM_DETAILS = gql`
  query getDicomDetails($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      name
      dicoms {
        id
        createdAt
        validDicom
        dicomAmount
        dicomBulletins {
          id
          coinType
          amount
          market
          drawer
          debtType
          doctoNumber
          documentType
          expirationDate
          doctoEntryDate
        }
        shareholders {
          id
          createdAt
          rut
          dv
          name
          participation
          dateVinculation
          dateInformation
          email
          type
        }
        societies {
          id
          createdAt
          rut
          dv
          name
          capital
          beginDate
        }
        externalQueries {
          id
          createdAt
          businessName
          date
          use
          platform
        }
        dicomScores {
          id
          createdAt
          date
          score
        }
      }
    }
  }
`;

export const DICOM_PLATINUM_DETAILS = gql`
  query getDicomPlatinumDetails($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      name
      dicomsPlatinum {
        id
        createdAt
        validDicom
        dicomAmount
        dicomBulletins {
          id
          coinType
          amount
          market
          drawer
          debtType
          doctoNumber
          documentType
          expirationDate
          doctoEntryDate
        }
        societies {
          id
          createdAt
          rut
          dv
          name
          capital
          beginDate
        }
        externalQueries {
          id
          createdAt
          businessName
          date
          use
          platform
        }
        dicomScores {
          id
          createdAt
          date
          score
        }
      }
    }
  }
`;

export const GET_RULE_RESULTS = gql`
  query getRuleResults($documentId: Int!, $modelName: String!) {
    getRuleResults(documentId: $documentId, modelName: $modelName) {
      id
      modelType
      rejects
      threshold
      name
      value
      prize
      logicalType
      labelName
      description
      valueParser
    }
  }
`;

export const GET_COMPANIES_BUREAUS = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          ...MasterEntityBasicField
          riskBlacklist {
            ...RiskBlacklistFields
          }
          currentDicomPlatinum {
            id
            createdAt
            dicomAmount
          }
          currentDicom {
            id
            createdAt
            dicomAmount
          }
          currentBusinessProfile {
            id
            createdAt
          }
          currentCurrentBehavior {
            id
            createdAt
          }
          ...CompanyDebtsSummary
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${MasterEntityBasicField}
  ${RiskBlacklistFields}
`;

export const MASTER_ENTITY_PROFILE = gql`
  query masterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      ...MasterEntityBasicField
      currentDicomPlatinum {
        id
        createdAt
      }
      currentDicom {
        id
        createdAt
      }
      country {
        id
        name
      }
      creditlines {
        edges {
          node {
            ...CreditLineFields
          }
        }
      }
      debtor {
        id
        ratificator {
          ...UserIdentification
        }
      }
      evaluationComments {
        ...EvaluationCommentFields
      }
      contacts(first: 1, showHidden: false) {
        edges {
          node {
            id
            name
            position
            email
            phoneNumber
          }
        }
      }
      company {
        id
        rut
        name
        hasSiiCredentials
        hasInvoiceProviderCredentials
        hasDigitalCertificate
        hasSupplierPortalCredentials
        hasFrameworkContract
        hasLendingAcceptedterms @client
        currentUserAcceptedTerms {
          id
          product
        }
        hasSiiCredentials @client
        hasInvoiceProviderCredentials @client
        hasSupplierPortalCredentials @client
        currentSurplusBalanceValue {
          ...MoneyFields
        }
        executiveAssigned {
          ...UserIdentification
        }
        masterEntity {
          ...MasterEntityBasicField
        }
        cessionsHistory {
          id
          cessionsNumber
          cessionsMoneyAmount
          historicCessionsNumber
          historicCessionsMoneyAmount
        }
        evaluationComments {
          id
          createdAt
          comments
          user {
            id
            firstName
            lastName
          }
        }
        documents {
          ...CompanyDocumentsFields
        }
        validCredentials {
          ...CredentialFields
        }
      }
    }
  }
  ${MoneyFields}
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${CompanyDocumentsFields}
  ${CredentialFields}
  ${CreditLineFields}
  ${EvaluationCommentFields}
`;

export const MASTER_ENTITY_WHITELISTS = gql`
  query masterEntity(
    $masterEntityId: Int!
    $active: Boolean
    $first: Int
    $offset: Int
  ) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      ...MasterEntityBasicField
      rateWhitelistEmitter(active: $active, first: $first, offset: $offset) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        totalPages
        edges {
          cursor
          node {
            ...RateWhitelistFields
          }
        }
      }
      rateWhitelistReceiver(active: $active, first: $first, offset: $offset) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        totalPages
        edges {
          cursor
          node {
            ...RateWhitelistFields
          }
        }
      }
    }
  }
  ${RateWhitelistFields}
  ${MasterEntityBasicField}
`;

export const COMPANY_LEGAL_REPRESENTATIVES = gql`
  query MasterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      company {
        id
        legalRepresentative {
          id
          source
          phone
          isLegalRepresentative
          isPartner
          isCurrentSociety
          relatedPerson {
            id
            name
            rut
            isNaturalPersonRut
            currentDicomPlatinum {
              createdAt
              dicomAmount
            }
            currentDicom {
              createdAt
              dicomAmount
            }
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
        }
      }
    }
  }
  ${RiskBlacklistFields}
`;

export const GET_RESTRICTIONS_UNCREATED = gql`
  query getCompanyRestrictionUncreated($companyId: String!) {
    getCompanyRestrictionsUncreated(companyId: $companyId) {
      label
      value
      created
    }
  }
`;

export const GET_PENDING_COMPANY_RESTRICTIONS = gql`
  query GetPendingCompanyRestriction($companyId: String!) {
    getPendingCompanyRestrictions(companyId: $companyId) {
      id
      label
    }
  }
`;

export const GET_COMPANY_CONTRACT_GENERATION = gql`
  query getCompanyContractGeneration($companyId: Int!) {
    company(companyId: $companyId) {
      id
      name
      mainActivity
      address
      isNaturalPerson
      legalRepresentative {
        email
        label
        nationality
        profession
        civilStatus
        relatedPerson {
          id
          name
          rut
          displayNationalIdentifier
        }
      }
      lastPendingSignatureContractAnnex {
        id
        signatureDate
        companyContractName
      }
      endorsements {
        id
        label
        spouceLabel
        nationality
        profession
        civilStatus
        address
        spouceAddress
        email
        personeriaComments
        relatedPerson {
          id
          name
          rut
          displayNationalIdentifier
        }
        relatedSpouce {
          id
          name
          rut
          displayNationalIdentifier
        }
      }
      companyframeworkcontractregisterSet {
        id
      }
      legalReport {
        id
        societyType
        signatureCount
        factoringAvailable
        creditOperationsAvailable
        personeriaComments
        managementPeople {
          id
          source
          phone
          isLegalRepresentative
          isPartner
          isCurrentSociety
          relatedPerson {
            id
            name
            rut
            displayNationalIdentifier
            isNaturalPersonRut
            currentDicomPlatinum {
              id
              createdAt
              dicomAmount
            }
            currentDicom {
              id
              createdAt
              dicomAmount
            }
          }
        }
      }
      masterEntity {
        id
        rut
        displayNationalIdentifier
      }
      pendingFrameworkContractRequest {
        id
        status
        externalContract {
          id
        }
        restriction {
          id
          contractType
          endorser {
            email
            label
            nationality
            profession
            civilStatus
            relatedPerson {
              id
              name
              rut
              displayNationalIdentifier
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_CONTRACT_GENERATION_SIMPLE = gql`
  query getCompanyContractGeneration($companyId: Int!) {
    company(companyId: $companyId) {
      id
      rut
      name
      lastPendingSignatureContractAnnex {
        id
        signatureDate
        companyContractName
      }
      masterEntity {
        id
        name
        rut
        displayNationalIdentifier
      }
      bankAccounts {
        id
        active
        bankName
        accountType
        accountNumber
        currency {
          id
          isoCode
        }
      }
      legalRepresentative {
        id
        source
        relatedPerson {
          id
          name
          displayNationalIdentifier
          address
        }
      }
      endorsements {
        relatedPerson {
          id
          name
          rut
          displayNationalIdentifier
          address
        }
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getCountries {
    getCountries {
      id
      name
    }
  }
`;

export const PUBLIC_MASTER_ENTITIES = gql`
  query publicMasterEntities($globalFilter: String!) {
    publicMasterEntities(globalFilter: $globalFilter) {
      ...MasterEntityPublicInfoType
    }
  }
  ${MasterEntityPublicInfoType}
`;

export const COMPANY_REQUIRED_DOCUMENT_TYPES = gql`
  query getCompanyRequiredDocumentTypes($countryId: String!) {
    getCompanyRequiredDocumentTypes(countryId: $countryId) {
      id
      name
      shortName
      code
    }
  }
`;

export const COMPANY_DOCUMENT_TYPES = gql`
  query companyDocumentTypes($countryId: String!) {
    companyDocumentTypes(countryId: $countryId) {
      id
      name
      shortName
      code
    }
  }
`;

export const MASTER_ENTITY_PROFILE_AS_DEBTOR = gql`
  query masterEntityProfileAsDebtor($masterEntityId: Int!) {
    staffMasterEntity(masterEntityId: $masterEntityId) {
      ...MasterEntityBasicField
      invoicesActualPaymentTerm
      invoicesAgreedPaymentTermAverage
      avgRatificationDuration
    }
  }
  ${MasterEntityBasicField}
`;

export const STAFF_MASTER_ENTITY_PROFILE = gql`
  query staffMasterEntityProfile($masterEntityId: Int!) {
    staffMasterEntity(masterEntityId: $masterEntityId) {
      ...MasterEntityBasicField
      country {
        id
        name
      }
      creditLine {
        ...CreditLineFields
        creditLineEvaluationRequests {
          id
          status
        }
      }
      debtor {
        id
        ratificator {
          ...UserIdentification
        }
        ratificationInformation {
          id
        }
      }
      evaluationComments {
        ...EvaluationCommentFields
      }
      contacts(first: 1, showHidden: false) {
        edges {
          node {
            id
            name
            position
            email
            phoneNumber
          }
        }
      }
      company {
        id
        rut
        name
        hasSiiCredentials
        hasInvoiceProviderCredentials
        hasDigitalCertificate
        hasSupplierPortalCredentials
        hasFrameworkContract
        hasLendingAcceptedterms @client
        currentUserAcceptedTerms {
          id
          product
        }
        hasSiiCredentials @client
        hasInvoiceProviderCredentials @client
        hasSupplierPortalCredentials @client
        currentSurplusBalanceValue {
          ...MoneyFields
        }
        executiveAssigned {
          ...UserIdentification
        }
        masterEntity {
          ...MasterEntityBasicField
        }
        cessionsHistory {
          id
          cessionsNumber
          cessionsMoneyAmount
          historicCessionsNumber
          historicCessionsMoneyAmount
        }
        evaluationComments {
          id
          createdAt
          comments
          user {
            id
            firstName
            lastName
          }
        }
        documents {
          ...CompanyDocumentsFields
        }
        validCredentials {
          ...CredentialFields
        }
      }
    }
  }
  ${MoneyFields}
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${CompanyDocumentsFields}
  ${CredentialFields}
  ${CreditLineFields}
  ${EvaluationCommentFields}
`;

export const CREDIT_LINE_MOVEMENTS_OF_OBJECT = gql`
  query creditLineMovementsOfObject(
    $first: Int
    $offset: Int
    $globalAppId: String!
    $orderBy: String
  ) {
    creditLineMovementsOfObject(
      globalAppId: $globalAppId
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...CreditLineMovementFields
          creditLine {
            id
            masterEntity {
              ...MasterEntityBasicField
            }
          }
          creditlinetransferSet {
            ...CreditLineTransferTypeFields
          }
        }
      }
    }
  }
  ${CreditLineMovementFields}
  ${MasterEntityBasicField}
  ${CreditLineTransferTypeFields}
`;

export const CREDIT_LINE_EVALUATION_REQUESTS = gql`
  query creditLineEvaluationRequests(
    $first: Int
    $offset: Int
    $globalFilter: String
    $creditlines_CreditLineEvaluationRequests_Status: String
    $creditlines_CreditLineEvaluationRequests_Status_In: [String]
    $countryId: Int
    $currency: String
    $id_In: [String]
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      creditlines_CreditLineEvaluationRequests_Status: $creditlines_CreditLineEvaluationRequests_Status
      creditlines_CreditLineEvaluationRequests_Status_In: $creditlines_CreditLineEvaluationRequests_Status_In
      countryId: $countryId
      id_In: $id_In
      creditlineEvaluationRequestCurrency: $currency
      distinct: true
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...MasterEntityBasicField
          riskBlacklist {
            ...RiskBlacklistFields
          }
          creditLine(currency: $currency) {
            ...CreditLineFields
            creditLineEvaluationRequests {
              ...CreditLineEvaluationRequestFields
              creditlinesuggestionSet {
                ...CreditLineSuggestionTypeFields
              }
              creditlineevaluationvariablesSet {
                ...CreditlineEvaluationVariablesTypeFields
                creditlineevaluationindicatorsSet {
                  ...CreditlineEvaluationIndicatorsTypeFields
                }
              }
            }
          }
          creditLineEvaluationRequestsAll {
            id
          }
          ...CompanyDebtsSummary
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${CreditLineFields}
  ${MasterEntityBasicField}
  ${CreditLineEvaluationRequestFields}
  ${CreditLineSuggestionTypeFields}
  ${CreditlineEvaluationVariablesTypeFields}
  ${CreditlineEvaluationIndicatorsTypeFields}
  ${RiskBlacklistFields}
`;

export const CREDIT_LINE_EVALUATION_REQUESTS_BY_MASTERENTITY = gql`
  query creditLineEvaluationRequestsByMasterEntity(
    $masterEntityId: Int!
    $currency: String
  ) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      ...MasterEntityBasicField
      creditLine(currency: $currency) {
        ...CreditLineFields
        creditLineEvaluationRequestsAll {
          ...CreditLineEvaluationRequestFields
          creditlinesuggestionSet {
            ...CreditLineSuggestionTypeFields
          }
          creditlineevaluationvariablesSet {
            ...CreditlineEvaluationVariablesTypeFields
            creditlineevaluationindicatorsSet {
              ...CreditlineEvaluationIndicatorsTypeFields
            }
          }
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
  ${CreditLineEvaluationRequestFields}
  ${CreditLineSuggestionTypeFields}
  ${CreditlineEvaluationVariablesTypeFields}
  ${CreditlineEvaluationIndicatorsTypeFields}
`;

export const GET_PROCESSING_COMPANIES = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
    $company_Invoice_Status: String
    $distinct: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      countryId: $countryId
      company_Invoice_Status: $company_Invoice_Status
      distinct: $distinct
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          displayNationalIdentifier
          currencies {
            id
            isoCode
          }
          riskBlacklist {
            ...RiskBlacklistFields
          }
        }
      }
    }
  }
  ${RiskBlacklistFields}
`;

export const GET_MASTER_ENTITY_RESTRICTIONS = gql`
  query getMasterEntityRestrictions($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      rut
      name
      displayNationalIdentifier
      company {
        id
        name
        documents {
          ...CompanyDocumentsFields
        }
        companyevaluationrestrictionsSet {
          ...CompanyRestrictions
        }
      }
    }
  }
  ${CompanyDocumentsFields}
  ${CompanyRestrictions}
`;
