import { gql } from '@apollo/client';

const CREATE_DEBTOR_RATIFICATION_INFORMATION = gql`
  mutation CreateDebtorRatificationInformation(
    $comment: String!
    $masterEntityId: Int!
  ) {
    createDebtorRatificationInformation(
      comment: $comment
      masterEntityId: $masterEntityId
    ) {
      debtor {
        id
        ratificationInformation {
          id
          status
          comment
        }
      }
    }
  }
`;

export default CREATE_DEBTOR_RATIFICATION_INFORMATION;
