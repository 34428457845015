import { gql } from '@apollo/client';

const GET_COUNTRY_BY_NAME = gql`
  query getCountryByName($name: String!) {
    getCountryByName(name: $name) {
      id
      name
      graphqlId @client
      currencies {
        id
        isoCode
        symbol
        decimalPlaces
        separatorDecimals
        separatorThousands
      }
      documentTypes {
        id
        code
        name
        shortName
      }
    }
  }
`;

export default GET_COUNTRY_BY_NAME;
